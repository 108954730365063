.switchControl {
    align-items: flex-end;
    display: flex;
    padding: 5px 0;
}

.bottomPanelColumn {
    display: flex;
    justify-content: flex-end;
}
