.panelCell {
    align-items: center;
    display: flex;
    justify-content: center;
}

.panelButton {
    margin-left: 5px;
    width: 46px;
}
