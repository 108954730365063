.menuToggleItem {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 5px;
}

.menuToggleIcon {
    color: #fff;
    font-size: 24px;
}

.menuToggleButton {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 4px 9px;
    outline: none;
}

.menuItems {
    background-color: #354052;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 300px;
}

.menuItemIcon {
    width: 26px;
}

.menuItemFontIcon {
    font-size: 20px;
}

.menuBaseItem {
    color: #fff;
    cursor: pointer;
    user-select: none;
    width: inherit;
}

.menuItem {
    display: flex;
    box-sizing: content-box;
    cursor: pointer;
}

.menuItem:hover {
    background-color: #2b3443;
}

.menuItemLink {
    color: inherit;
    display: flex;
    flex-grow: 1;
    line-height: 21px;
    opacity: 0.7;
    padding: 10px 15px;
    white-space: nowrap;
}

.menuItemLink:focus,
.menuItemLink:active,
.menuItemLink:hover {
    color: inherit;
    opacity: 1;
    text-decoration: none;
}

.menuItemLink--current {
    opacity: 1;
}

.menuRightIcon {
    font-size: 14px;
    vertical-align: middle;
}

.menuItemLabel {
    display: inline-block;
    padding-left: 10px;
}

.menuItems--narrow .menuItemLabel {
    width: 245px;
}

.menuItems--narrow {
    width: 55px;
}

.menuItems--narrow .menuItem {
    overflow-x: hidden;
}

.menuItems--narrow > .menuItem > .menuItemLink,
.menuItems--narrow > .menuItem > .menuItemRelativeBox > .menuItemLink {
    background-color: #354052;
}

.menuItems--narrow .menuItem:hover {
    overflow-x: visible;
}

.menuItems--narrow .menuItemLabel {
    display: none;
}

.menuItems--narrow .menuItem:hover .menuItemLabel {
    display: inline-block;
}

.menuItems--narrow .menuItem:hover .menuItemLink {
    opacity: 1;
}

.menuItemRelativeBox {
    display: inline-block;
    flex-grow: 1;
    position: relative;
}

.menuItemLabelWrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.menuItemLabelText {
    display: inline-block;
    font-size: 17px;
    padding-right: 5px;
    max-width: 200px;
    overflow-wrap: anywhere;
    white-space: pre-line;
}

.menuItems--narrow .menuSubItems {
    left: 55px;
    top: 100%;
    position: absolute;
    width: calc(100% - 55px);
}

.menuSubItems .menuSubItems {
    position: static;
    width: 100%;
}

.menuItemLabel {
    flex-grow: 1;
}

.menuItems--narrow .menuSubItem,
.menuItems--narrow .menuSubItem .menuItemLink,
.menuItems--narrow .menuSubItem .menuItemLabel {
    width: 100%;
}

.menuSubItemLink {
    opacity: 1;
}

.menuSubItem {
    background-color: #455166;
}

.menuSubItemLink {
    padding-left: 50px;
}

.menuSubItem .menuSubItems .menuSubItemLink {
    padding-left: 100px;
}

.menuItems--narrow .menuSubItem {
    padding-left: 0;
}

.menuSubItem:hover {
    background-color: #181f29;
}

.menuItem--current {
    background-color: #2b3443;
    border-left: 4px solid #2099e6;
}

.expander {
    font-size: 16px;
    font-weight: 700;
    margin-right: 10px;
}

.menuItems--narrow .expander {
    display: none;
}
